@import url('https://fonts.googleapis.com/css2?family=Actor&display=swap');
@import '~react-toastify/dist/ReactToastify.css';

#root {
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  font-family: Actor !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

h1 {
  font-size: 30px !important;
  line-height: 36px !important;
  letter-spacing: 5px !important;
  text-align: left !important; 
}

h2 {
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: 5px !important;
  text-align: left !important; 
}

h3 {
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.2em !important;
  text-align: left !important;
  text-transform: uppercase;
}

.h-100 {
  height: 100vh;
}

a {
  text-decoration: none;
  color: $text-body;
  &:hover {
    text-decoration: underline;
    color: $link-primary;
  }
}

.heading {
  margin-top: 49px;
  margin-left: 24px;
  margin-bottom: 20px;
}