.left-nav {
  background: linear-gradient(180deg, rgba(38, 34, 80, 0.65) 0%, rgba(27, 25, 66, 0.85) 57.44%, #17163B 100%);
  width: 352px;
  height: 100vh;

  .btn-collapse {
    float: right;
    margin-right: 29px;
    margin-top: 5px;
  }
  .asset-list {
    margin-top: -19px;
    &:not(.collapsed) {
      .col {
        margin-bottom: 31px;
      }      
    }
    &.collapsed {
      display: none;
    }
  }
  .header {
    margin-top: 56px;
    margin-left: 127px;
    margin-bottom: 29px;
    h2 {
      padding: 0px;
    }
  }

  .nav-asset-item {
    margin-left: 55px;
    &.selected {
      color: $text-primary;
      a {
        color: $text-primary;
      }
    }
    .nav-item-link {
      font-family: Actor;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      .summary {
        display: block;
        margin-left: 36px;
      }
    }
  }
  .nav-item {
    height: 34px;
    margin-bottom: 30px;

    &.selected {
      color: #53B9EA !important;
      a {
        color: #53B9EA !important;
      }
      svg {
        filter: invert(61%) sepia(79%) saturate(378%) hue-rotate(164deg) brightness(93%) contrast(97%);
      }
    }
    .oval {
      padding:0px;
      width: 34px;
      position: relative;
      left: 0px;
      top: 0px;
    }
  }
  .nav-item-icon {
    width: 47px;
    padding: 0px;
  }
  .nav-item > .nav-item-link {
    padding: 0px;
    font-family: Actor;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  :not(.selected) svg {
    filter: invert(93%) sepia(0%) saturate(0%) hue-rotate(148deg) brightness(94%) contrast(95%);
  }
  
  .logo {
    position: absolute;
    width: 58px;
    height: 58px;
    left: 62px;
    top: 48px;
    filter: none !important;
  }
}

@media (max-width: 576px) {  
 .footer {
   display: none !important;
 }
}