.home {
  .btn {
    border-radius: 10px;
  }
}
// INTRO
.intro {
  // background: url('/img/pixel_pattern.png');
  min-height: 800px;
  .intro-btns {
    justify-content: space-around;
  }
}

.bulletin {
  padding: 34px;
  img {
    margin-bottom: 3px;
  }
}

.features {
  min-height: 977px;
  color: $text-primary;
  .features-card-body {
    max-width: 1300px;
  }
}

.product {
  background: $white;
  min-height: 977px;
  color: $text-primary;
  text-align: left;
  .product-description {
    text-align: left;
  }
}

.btn {
  width: 280px;
  height: 67px;
  font-size: 20px !important;
}