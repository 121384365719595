@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$primary: #2D3166;
$secondary: #FAB445;
$danger: #E3507A;
$success: #50E3C2;
$link-primary: #53B9EA;
$header: #21255C;
$body-bg: #17163B;
$body-bg2: #F2F2F2;
$body-color: #ffffff;
$border-radius: 5px;
$btn-border-radius: 5px;
$border-color: #404373;
$border-color2: #404373;
$input-bg: #EDF1F4;
$primary-color2: #2C55FF;
$text-primary: #FFFFFF; // headers
$text-secondary: #B1AFCD; // subheaders
$text-body: #6F6C99; // main
$input-placeholder-color: $text-secondary;
$btn-gradient-start: #5FADFF;
$btn-gradient-end: #86C1FF;

/* make the customizations */
$theme-colors: (
    primary: $primary,
    primaryLight: #B1E1FF,
    primaryMid: #5FADFF,
    primaryDark: #3B82FF,
    primaryShadow: rgba(45,49,102,0.3),
    secondary: $secondary,
    inactive: #D8D5DD,
    info: #2552a5,
    danger: $danger,
    warning: #a0931b,
    success: $success,
    // CUSTOM STARTS HERE
    primary2: $primary-color2,
    bg: $body-bg,
    bg2: $body-bg2,
    input: $input-bg,
    // nav: $nav-bg,
    header: $header,
    // panel: $panel-bg,
    white: #ffffff,
    textPrimary: $text-primary,
    textSecondary: $text-secondary,
);

.btn-secondary {
    color: $body-color !important;
}

.btn-inactive {
    color: $text-secondary !important;
    opacity: 0.5;
}

// Override scrollbar
div::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
    }
      
div::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px $border-color;
box-shadow: inset 0 0 6px $border-color;
background-color: inherit;
}

div::-webkit-scrollbar-thumb {
background-color: $border-color2;
outline: 1px solid $border-color2;
border-radius: 3px;
}

@import "~bootstrap/scss/bootstrap";
@import "../components/styles";
@import "./main.scss";