.asset-view {
  max-height: 100vh;
  overflow: auto;
  .sub-heading {
    margin-left: 25px;
    margin-bottom: 22px;
  }

  .chart {
    height: 303px;
    margin-bottom: 32px;
    border: 1px solid;
  }

  .spread-tile, .spread-tile-selected {
    margin: 0px 23px 23px 0px;
  }

  .spread-tiles {
    justify-content: start;
    margin-left: 30px;
    margin-right: 30px;

    .graph-red {
      --color-gred1: #A330FF;
      --color-gred2: #BC3DCB;
      --color-gred3: #E3507A;
    }
    .graph-green {
      --color-ggreen1: #309AFF;
      --color-ggreen2: #5364AE;
      --color-ggreen3: #70366B;
    }

    .glow-red {
      position: relative;
      top: -35px;
      left: 82px;
    }
    .glow-green {
      position: relative;
      top: -43px;
      left: 82px;
    }
    .arrow {
      margin-left: 10px;
    }
    h3 {
      margin-left: 10px;
      margin-top: 18px;
      letter-spacing: normal !important;
    }
    .spread-change {
      font-size: 17px;
      line-height: 20px;
      margin-top: 13px;
    }
    .graph {
      margin-left: 72px;
      .text-danger, .text-success {
        margin-left: 10px;
        font-size: 11px;
        line-height: 13px;
        vertical-align: middle;
      }
    }

    .spread-tile-selected {
      height: 74px;
      width: 230px;
      background: linear-gradient(135deg, #40DDFF 0%, #14BAE3 19.24%, #13B1E6 68.64%, #11AADF 81.77%, #0B98C5 100%);
      border-radius: 10px;
      box-shadow: -26px 9px 45px -20px rgb(11, 152, 197);
      h3 {
        opacity: 0.5;
      }
      // NOTE: this doesn't work because it overrides the variable for every occurrence
      // Would need to change the .svg to a component which returns <svg> and has props
      // to determine what stop-color gets passed
      // .graph-red {
      //   --color-gred1: #ffffff;
      //   --color-gred2: #ffffff;
      //   --color-gred3: #ffffff;
      // }
      // .graph-green {
      //   --color-ggreen1: #ffffff;
      //   --color-ggreen2: #ffffff;
      //   --color-ggreen3: #ffffff;
      // }
      .arrow path {
        fill: #ffffff;
      }
      .text-danger, .text-success {
        color: #fff !important;
      }
    }
    .spread-tile {
      cursor: pointer;
      width: 230px;
      height: 74px;
      mix-blend-mode: normal;
      border: 1px solid #312F62;
      box-sizing: border-box;
      border-radius: 10px;
      h3 {
        opacity: 0.2;
      }
      .spread-change {
        opacity: 0.7;
      }
    }
  }
}


