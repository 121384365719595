.custom-card {
  max-width: 378px !important;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  h5 {
    font-weight: 700;
  }
  h6 {
    font-size: 14px;
    font-weight: 400;
  }
  .body {
    font-size: 18px;
  }
  .custom-card-img {
    height: 70px;
  }
}